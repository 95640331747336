import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { constant } from "../../../utils/constant";
import { saveShippingDetails } from "../../../modules/sellerProduct/saveShippingDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../utils/types";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getCommonData } from "../../../modules/common/getCommonData";
import { getCountries } from "../../../modules/common/getCountries";
import { getStateByCountryCode } from "../../../modules/common/getStateByCountryCode";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { notify } from "../../../modules/notification/notify";
import { reset } from "../../../modules/sellerProduct/reset";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSellerProductDetails } from "../../../modules/sellerProduct/getSellerProductDetails";
import { resetCategorySuccess } from "../../../modules/categories/slice";
import { fetchCourierProviderDetails, fetchCourierServiceDetails } from "../../../modules/sellerProduct/api";
import { getCourierServiceDetails } from "../../../modules/sellerProduct/getCourierServiceDetails";
import { useNavigate } from "react-router-dom";
interface Props {
  nextStep: any;
  setProductId: any;
  prevStep: any;
  productId: any;
  setstep?: any;
}

const ShippingDetails = ({
  prevStep,
  nextStep,
  setProductId,
  productId,
  setstep,
}: Props) => {
  interface shippingValues {
    productId: string;
    // domesticShipping: number | string | null;
    // shippingService: number | null;
    // shippingCost: number | null;
    isFreeShipping: boolean;
    // deliveryIn: number | string;
    // internationalShipping: number | string;
    // packageType: number | string;
    packageDimension: number | null;
    packageDimensionX: number | null;
    packageDimensioY: number | null;
    // weightType: number | string;
    weightLb: number | null;
    weightOz: number | null;
    sellerMobileNo: number | null;
    itemAddress1: string;
    itemAddress2: string;
    itemCity: string;
    itemStateId: string | null;
    itemCountryId: string | null;
    itemZipCode: number | null;
    Isdraft: boolean;
    DomesticCourierServiceId:number | string;
    InternationalCourierServiceId:number | null ;
  }

  const [shippingData, setShippingData] = useState<shippingValues>({
    productId                     :localStorage.getItem("productId") !== null ? localStorage.getItem("productId") : productId,
    isFreeShipping                : false,
    packageDimension              : null,
    packageDimensionX             : null,
    packageDimensioY              : null,
    weightLb                      : null,
    weightOz                      : null,
    sellerMobileNo                : null,
    itemAddress1                  : "",
    itemAddress2                  : "",
    itemCity                      : "",
    itemStateId                   : null,
    itemCountryId                 : null,
    itemZipCode                   : null,
    Isdraft                       : false,
    DomesticCourierServiceId      : "",
    InternationalCourierServiceId : null,
  });

  const [render, setRender] = useState(0);

  const isFirstRender = useRef(true);
  const commonResponse = useSelector<RootState, RootState["common"]>(CommonResponseSelector);
  const sellerProductResponse = useSelector<RootState,RootState["sellerProduct"]>(SellerProductResponseSelector);
  // const [addressAutoFocus, setAddressAutoFocus] = useState(false);
  const navigate = useNavigate();
  let submitAction: string | undefined = undefined;
  const [draft, setDraft] = useState(false);
  const ShippingSchema = Yup.object().shape({
    // domesticShipping: Yup.string().required("*Required!"),
    // deliveryIn: Yup.string().required("*Required!"),
    DomesticCourierServiceId: Yup.string().required("*Required!"),

    // internationalShipping: Yup.string().required("*Required!"),
    itemCountryId: Yup.string().required("*Required!"),
    itemStateId: Yup.string().required("*Required!"),
    itemCity: Yup.string().required("*Required!"),
    sellerMobileNo: Yup.string()
      .typeError("you must specify a number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Min value 10 digit.")
      .max(15, "Max value 15 digit.")
      .required("*Required!"),
    itemZipCode: Yup.string()
      .min(4, "Min value 4 characters.")
      .max(15, "Max value 15 characters.")
      .required("*Required!"),
    itemAddress1: Yup.string().required("*Required!"),
    // packageType: Yup.string().required("*Required!"),
    // weightType: Yup.string().required("*Required!"),
    // shippingCost: Yup.number()
    //   .nullable()
    //   .typeError("Must be only digits")
    //   .test(
    //     "Is positive?",
    //     "The number must be greater than 0 and less than 100000000",
    //     (value) =>
    //       value === undefined ||
    //       value === null ||
    //       (value > 0 && value <= 99999999.99)
    //   ),
    packageDimension: Yup.number()
      .nullable().required("*Required!")
      .typeError("Must be only digits")
      .test(
        "Is positive?",
        "The number must be in range 0.001 to 99999999.99",
        (value) =>
          value === undefined ||
          value === null ||
          (value >= 0.001 && value <= 99999999.99)
      ),
    packageDimensionX: Yup.number()
      .nullable().required("*Required!")
      .typeError("Must be only digits")
      .test(
        "Is positive?",
        "The number must be in range 0.001 to 99999999.99",
        (value) =>
          value === undefined ||
          value === null ||
          (value >= 0.001 && value <= 99999999.99)
      ),
    packageDimensioY: Yup.number()
      .nullable().required("*Required!")
      .typeError("Must be only digits")
      .test(
        "Is positive?",
        "The number must be in range 0.001 to 99999999.99",
        (value) =>
          value === undefined ||
          value === null ||
          (value >= 0.001 && value <= 99999999.99)
      ),
    weightLb: Yup.number()
      .nullable().required("*Required!")
      .typeError("Must be only digits")
      .test(
        "Is positive?",
        "The number must be in range 0.001 to 999999999.999",
        (value) =>
          value === undefined ||
          value === null ||
          (value >= 0.001 && value <= 999999999.999)
      ),
    weightOz: Yup.number()
      .nullable()
      .typeError("Must be only digits")
      .test(
        "Is positive?",
        "The number must be in range 0.001 to 999999999.999",
        (value) =>
          value === undefined ||
          value === null ||
          (value >= 0.001 && value <= 999999999.999)
      ),
  });
  const dispatch = useDispatch();

  const submitFormData = (values: any) => {
    // checking if value of first name and last name is empty show error else take to step 2

    // let formData = new FormData();
    // formData.append("listing", JSON.stringify(values));
    dispatch<any>(saveShippingDetails(JSON.stringify(values)));

    // setProductId("41F84D18-02D5-4835-BDE0-5D22740FB8C8")
    // nextStep();
  };

  const onChange = (e: any) => {
    setShippingData({
      ...shippingData,
      [e.target.name]: e.target.value,
      isFreeShipping : ([3,4,5].find(item => item == e.target.value) ? false : false)
    });
  };

  const onCheckboxChange = (e: any) => {
    let value = e.target.checked ? true : false;
    // setFree(value);

    setShippingData({
      ...shippingData,
      [e.target.name]: value,
      // shippingCost: null,
    });
  };

  let autoComplete: any;
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (isFirstRender.current) {
      clearAddress();

      // loadScript(
      //   `https://maps.googleapis.com/maps/api/js?key=${constant.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
      //   () => handleScriptLoad(autoCompleteRef)
      // );
      
      if (commonResponse.common === null) dispatch<any>(getCommonData());
      if (commonResponse.countries === null) dispatch<any>(getCountries());
      if (sellerProductResponse.sellerProductDetails == null)
        dispatch<any>(getSellerProductDetails(shippingData.productId));
    
        dispatch<any>(getCourierServiceDetails());

    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (sellerProductResponse?.sellerProductDetails?.value?.length > 0) {
      setShippingData({
        ...shippingData,
          productId                     : localStorage.getItem("productId") !== null ? localStorage.getItem("productId") : productId,
          isFreeShipping                : sellerProductResponse?.sellerProductDetails?.value[0].IsFreeShipping ? sellerProductResponse?.sellerProductDetails?.value[0].IsFreeShipping : false,
          packageDimension              : sellerProductResponse?.sellerProductDetails?.value[0].PackageDimension? sellerProductResponse?.sellerProductDetails?.value[0].PackageDimension : null,
          packageDimensionX             : sellerProductResponse?.sellerProductDetails?.value[0].PackageDimensionX? sellerProductResponse?.sellerProductDetails?.value[0].PackageDimensionX : null,
          packageDimensioY              : sellerProductResponse?.sellerProductDetails?.value[0].PackageDimensioY? sellerProductResponse?.sellerProductDetails?.value[0].PackageDimensioY : null,
          weightLb                      : sellerProductResponse?.sellerProductDetails?.value[0].WeightLb? sellerProductResponse?.sellerProductDetails?.value[0].WeightLb : null,
          weightOz                      : sellerProductResponse?.sellerProductDetails?.value[0].WeightOz? sellerProductResponse?.sellerProductDetails?.value[0].WeightOz : null,
          sellerMobileNo                : sellerProductResponse?.sellerProductDetails?.value[0].SellerMobileNo? sellerProductResponse?.sellerProductDetails?.value[0].SellerMobileNo: null,
          itemAddress1                  : sellerProductResponse?.sellerProductDetails?.value[0].ItemAddress1? sellerProductResponse?.sellerProductDetails?.value[0].ItemAddress1 : "",
          itemAddress2                  : sellerProductResponse?.sellerProductDetails?.value[0].ItemAddress2? sellerProductResponse?.sellerProductDetails?.value[0].ItemAddress2 : "",
          itemCity                      : sellerProductResponse?.sellerProductDetails?.value[0].ItemCity? sellerProductResponse?.sellerProductDetails?.value[0].ItemCity : "",
          itemCountryId                 : sellerProductResponse?.sellerProductDetails?.value[0].ItemCountryId?.toString()? sellerProductResponse?.sellerProductDetails?.value[0]?.ItemCountryId?.toString() : null,
          itemStateId                   : sellerProductResponse?.sellerProductDetails?.value[0].ItemStateId?.toString()? sellerProductResponse?.sellerProductDetails?.value[0]?.ItemStateId?.toString(): null,
          itemZipCode                   : sellerProductResponse?.sellerProductDetails?.value[0].ItemZipCode? sellerProductResponse?.sellerProductDetails?.value[0].ItemZipCode : null,
          DomesticCourierServiceId      : sellerProductResponse?.sellerProductDetails?.value[0].DomesticCourierServiceId? sellerProductResponse?.sellerProductDetails?.value[0].DomesticCourierServiceId : "",
          InternationalCourierServiceId : sellerProductResponse?.sellerProductDetails?.value[0].InternationalCourierServiceId? sellerProductResponse?.sellerProductDetails?.value[0].InternationalCourierServiceId : null,
        // Isdraft: sellerProductResponse?.sellerProductDetails?.value[0].DomesticShipping ?sellerProductResponse?.sellerProductDetails?.value[0].DomesticShipping  : false,
      });    
    }
  }, [sellerProductResponse.sellerProductDetails]);

  useEffect(() => {
    // dispatch<any>(EndProgress());
    if (sellerProductResponse.saveData != null) {
      if (sellerProductResponse.saveData.success == "Y") {
        notify(sellerProductResponse.saveData.message, constant.SUCCESS);
        // resetForm();
        // setSellingData({
        //   ...sellingData,
        //   productId: sellerProductResponse.saveData.productId,
        // });
        if(draft)
        {
         navigate("/seller/draftListing");
        }
        else
        {

        dispatch<any>(
          getSellerProductDetails(localStorage.getItem("productId"))
        ).then(nextStep());
        }
      } else if (sellerProductResponse.saveData.success == "N") {
        notify(sellerProductResponse.saveData.message, constant.ERROR);
      }
      dispatch<any>(reset("saveData"));

      // dispatch<any>(resetBanner());
    }
  }, [sellerProductResponse.saveData]);

  useEffect(() => {
    let countryData = sellerProductResponse?.sellerProductDetails?.value[0]?.ItemCountryId ? commonResponse?.countries?.value.find(
      (item: any) =>
        item.Id ==
        sellerProductResponse?.sellerProductDetails?.value[0]?.ItemCountryId
    ) : undefined;
    if (countryData !== undefined && render == 0) {
      dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
      setRender(1);
    }
  }, [sellerProductResponse.sellerProductDetails, commonResponse.countries]);

  const handleCountryChange = (e: any) => {
    setShippingData({
      ...shippingData,
      [e.target.name]: e.target.value,
    });

    let countryCode =
      e.target[e.target.selectedIndex].getAttribute("data-code");
    dispatch<any>(getStateByCountryCode(countryCode));
  };

  const clearAddress = () => {
    setShippingData((prevState: any) => ({
      ...prevState,
      itemAddress1: "",
      itemAddress2: "",
      itemCity: "",
      itemStateId: "",
      itemCountryId: "",
      itemZipCode: "",
    }));
  };

  const handleAdress = (e: any) => {
    switch (e.target.name) {
      case "itemAddress1":
        setShippingData((prevState: any) => ({
          ...prevState,
          itemAddress1: e.target.value,
        }));
        break;
      default:
        return;
    }
  };

  const goToPrevious = (e: any) => {
    e.preventDefault();
    prevStep();
  };
  ([3,4,5].some(item => item !== (shippingData.DomesticCourierServiceId)))
// useEffect(()=>{
//   callDomesticServices()
// },[domesticProvider])
// useEffect(()=>{
//   callInternationalServices()
// },[internationalProvider])

// const callDomesticServices =async ()=>{
// const services= await fetchCourierServiceDetails({id:1,type:1})
// }
// const callInternationalServices = async ()=>{
// const services= await fetchCourierServiceDetails({id:1,type:2})
  
// }

  return (
    <>
      <ToastContainer />
      <div className="tabs-nav-block">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <div
              className="nav-link "
              style={{
                cursor: "pointer",
              }}
              onClick={() => setstep(1)}
            >
              Listing detail
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.Format !== null &&
                setstep(2)
              }
            >
              Selling details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link active "
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.DomesticCourierServiceId !== null &&
                setstep(3)
              }
            >
              Shipping details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.VolumePricing &&
                setstep(4)
              }
            >
              Sell it faster
            </div>
          </li>
        </ul>
      </div>

      <Formik
        initialValues={shippingData}
        validationSchema={ShippingSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          let data = { ...values };
          if (submitAction === "primary") {
            data.Isdraft = true;
            submitFormData(data);
            setDraft(true);

          } else {
            // set state to show warning message
            if( sellerProductResponse?.sellerProductDetails?.value[0].IsListingActive==null || sellerProductResponse?.sellerProductDetails?.value[0].IsListingActive==false)
           { data.Isdraft = true;}
            submitFormData(data);
          }
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <>
            <Form>
              <div className="seller-form-block">
                <div className="container">
                  <div className="row">
               

                    {/* <div className="col-md-5">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Domestic Shipping
                        </label>
                        <select
                          className="form-control input-seller"
                          name="domesticShipping"
                          value={
                            shippingData.domesticShipping === null
                              ? ""
                              : shippingData.domesticShipping
                          }
                          onChange={onChange}
                        >
                          <option value="">Choose shipping</option>
                          {commonResponse.common?.domesticShipping?.map(
                            (item, Index) => (
                              <option value={item.value}>{item.label}</option>
                            )
                          )}
                        </select>
                        {errors.domesticShipping && touched.domesticShipping ? (
                          <div className="required">
                            {errors.domesticShipping}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-5">
                      <div className="form-group">
                        <label className="label-seller">Shipping Service</label>
                        <input
                          className="form-control input-seller"
                          name="shippingService"
                          onChange={onChange}
                          value={
                            shippingData.shippingService === null
                              ? ""
                              : shippingData.shippingService
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-2">
                      <div className="form-group">
                        <label className="label-seller">Cost ($)</label>
                        <input
                          type="text"
                          className="form-control input-seller"
                          name="shippingCost"
                          onChange={(e) =>
                            setShippingData({
                              ...shippingData,
                              [e.target.name]:
                                e.target.value === "" ? null : e.target.value,
                            })
                          }
                          readOnly={free}
                          value={
                            shippingData.shippingCost === null
                              ? ""
                              : shippingData.shippingCost
                          }
                          placeholder="$00"
                        />
                        {errors.shippingCost && touched.shippingCost ? (
                          <div className="required">{errors.shippingCost}</div>
                        ) : null}
                      </div>
                    </div> */}
                    {([3,4,5].find(item => item == shippingData.DomesticCourierServiceId)) ?
                    <div className="col-md-12 col-lg-2 text-muted">
                      <div className="form-group">
                        <label className="label-seller text-muted" style={{cursor:'not-allowed'}}>Free shipping</label>
                        <div className="item-sub-group">
                          <div className="custom-control custom-checkbox custom-control-inline checkbox-xl">
                            <input type="checkbox" disabled className="custom-control-input" id="makeFree" name="isFreeShipping" style={{cursor:'not-allowed'}}/>
                            <label className="custom-control-label" htmlFor="makeFree" style={{cursor:'not-allowed'}}>Make free</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="col-md-12 col-lg-2">
                      <div className="form-group">
                        <label className="label-seller">Free shipping</label>
                        <div className="item-sub-group">
                          <div className="custom-control custom-checkbox custom-control-inline checkbox-xl">
                            <input type="checkbox" className="custom-control-input" id="makeFree" onChange={onCheckboxChange} name="isFreeShipping" checked={shippingData.isFreeShipping} style={{cursor:'pointer'}}/>
                            <label className="custom-control-label" htmlFor="makeFree" style={{cursor:'pointer'}}>Make free</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </div>

                  {/* <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Delivery in
                        </label>
                        <select
                          className="form-control input-seller"
                          name="deliveryIn"
                          value={shippingData.deliveryIn}
                          onChange={onChange}
                        >
                          <option value=""> Choose Type</option>
                          {commonResponse.common?.deliveryIn.map(
                            (item, Index) => (
                              <option value={item.value}>{item.label}</option>
                            )
                          )}
                        </select>
                        {errors.deliveryIn && touched.deliveryIn ? (
                          <div className="required">{errors.deliveryIn}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>International
                          Shipping
                        </label>
                        <select
                          className="form-control input-seller"
                          name="internationalShipping"
                          value={shippingData.internationalShipping}
                          onChange={onChange}
                        >
                          <option value="">Choose</option>
                          {commonResponse?.common?.internationalShipping?.map(
                            (item) => (
                              <option key={item.key} value={item.value}>
                                {item.label}
                              </option>
                            )
                          )}
                        </select>
                        {errors.internationalShipping &&
                        touched.internationalShipping ? (
                          <div className="required">
                            {errors.internationalShipping}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Domestic shipping provider
                        </label>
                        <select
                          className="form-control input-seller"
                          name="DomesticCourierServiceId"
                          value={shippingData.DomesticCourierServiceId}
                          onChange={onChange}
                        >
                          <option value=""> Choose Type</option>
                          {sellerProductResponse?.courierService?.data?.domesticServices?.map(
                            (item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </select>
                        {errors.DomesticCourierServiceId && touched.DomesticCourierServiceId ? (
                          <div className="required">{errors.DomesticCourierServiceId}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          International shipping provider
                          
                        </label>
                        <select
                          className="form-control input-seller"
                          name="InternationalCourierServiceId"
                          value={shippingData.InternationalCourierServiceId ? shippingData.InternationalCourierServiceId : ""}
                          onChange={(e)=>setShippingData({...shippingData, InternationalCourierServiceId:e.target.value===""? null :Number(e.target.value)})}
                        >
                          <option value="">Choose</option>
                          {sellerProductResponse?.courierService?.data?.internationalServices?.map(

                            (item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </select>
                       
                      </div>
                    </div> 
                  </div>
                
                  <div className="row">
                    <div className="col-md-12">
                      <div className="item-group">
                        <label className="label-seller">
                          Package weight &amp; dimensions
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller item-specify">
                          <span className="red-star">*</span> Package type
                        </label>
                        <select
                          className="form-control input-seller"
                          value={shippingData.packageType}
                          name="packageType"
                          onChange={onChange}
                        >
                          <option value="">Choose Type</option>

                          {commonResponse?.common?.packageType?.map((item) => (
                            <option key={item.key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {errors.packageType && touched.packageType ? (
                          <div className="required">{errors.packageType}</div>
                        ) : null}
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label-seller item-specify">
                        <span className="red-star">*</span> Dimensions (inches)
                        </label>
                        <div className="shipping-weight-block">
                          <div>
                            <input
                              type="text"
                              className="form-control input-seller"
                              name="packageDimension"
                              onChange={(e) =>
                                setShippingData({
                                  ...shippingData,
                                  [e.target.name]:
                                    e.target.value == ""
                                      ? null
                                      : e.target.value,
                                })
                              }
                              value={
                                shippingData.packageDimension === null
                                  ? ""
                                  : shippingData.packageDimension
                              }
                              placeholder="00"
                            />
                            {errors.packageDimension &&
                            touched.packageDimension ? (
                              <div className="required">
                                {errors.packageDimension}
                              </div>
                            ) : null}
                          </div>
                          <span>Length</span>
                          <div>
                            {" "}
                            <input
                              type="text"
                              className="form-control input-seller"
                              name="packageDimensionX"
                              onChange={(e) =>
                                setShippingData({
                                  ...shippingData,
                                  [e.target.name]:
                                    e.target.value == ""
                                      ? null
                                      : e.target.value,
                                })
                              }
                              value={
                                shippingData.packageDimensionX === null
                                  ? ""
                                  : shippingData.packageDimensionX
                              }
                              placeholder="00"
                            />
                            {errors.packageDimensionX &&
                            touched.packageDimensionX ? (
                              <div className="required">
                                {errors.packageDimensionX}
                              </div>
                            ) : null}
                          </div>
                          <span>Width</span>
                          <div>
                            <input
                              type="text"
                              className="form-control input-seller"
                              name="packageDimensioY"
                              onChange={(e) =>
                                setShippingData({
                                  ...shippingData,
                                  [e.target.name]:
                                    e.target.value == ""
                                      ? null
                                      : e.target.value,
                                })
                              }
                              value={
                                shippingData.packageDimensioY === null
                                  ? ""
                                  : shippingData.packageDimensioY
                              }
                              placeholder="00"
                            />
                            {errors.packageDimensioY &&
                            touched.packageDimensioY ? (
                              <div className="required">
                                {errors.packageDimensioY}
                              </div>
                            ) : null}
                          </div>
                          <span>Height</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label-seller item-specify">
                          <span className="red-star">*</span> Weight
                        </label>
                        <div className="shipping-weight-block">
                          {/* <select
                            className="form-control input-seller"
                            name="weightType"
                            onChange={onChange}
                            value={shippingData.weightType}
                          >
                            <option value="">choose</option>
                            {commonResponse?.common?.weight?.map((item) => (
                              <option key={item.key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                         
                          <span>lbs</span> */}
                          <div>
                            <input
                              type="text"
                              className="form-control input-seller"
                              name="weightLb"
                              onChange={(e) =>
                                setShippingData({
                                  ...shippingData,
                                  [e.target.name]:
                                    e.target.value == ""
                                      ? null
                                      : e.target.value,
                                })
                              }
                              value={
                                shippingData.weightLb === null
                                  ? ""
                                  : shippingData.weightLb
                              }
                              placeholder="00"
                            />
                            {errors.weightLb && touched.weightLb ? (
                              <div className="required">{errors.weightLb}</div>
                            ) : null}
                          </div>
                          <span>lbs</span>
                          <div>
                            {" "}
                            <input
                              type="text"
                              className="form-control input-seller"
                              name="weightOz"
                              onChange={(e) =>
                                setShippingData({
                                  ...shippingData,
                                  [e.target.name]:
                                    e.target.value == ""
                                      ? null
                                      : e.target.value,
                                })
                              }
                              value={
                                shippingData.weightOz === null
                                  ? ""
                                  : shippingData.weightOz
                              }
                              placeholder="00"
                            />
                            {errors.weightOz && touched.weightOz ? (
                              <div className="required">{errors.weightOz}</div>
                            ) : null}
                          </div>
                          <span>oz</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Item location
                        </label>
                        <div className="label-seller-normal">
                          Seller Current Location{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          {" "}
                          <span className="red-star">*</span>Address line 1
                        </label>
                        <input
                          type="text"
                          className="form-control input-seller"
                          // ref={autoCompleteRef}
                          onChange={onChange}
                          name="itemAddress1"
                          value={shippingData.itemAddress1}
                          // autoFocus={addressAutoFocus}
                          placeholder="Address"
                        />
                        {errors.itemAddress1 && touched.itemAddress1 ? (
                          <div className="required">{errors.itemAddress1}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">Address line 2</label>
                        <input
                          type="text"
                          className="form-control input-seller"
                          name="itemAddress2"
                          onChange={onChange}
                          value={shippingData.itemAddress2}
                          placeholder="Address"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span> Country
                        </label>
                        <select
                          className="form-control input-seller"
                          name="itemCountryId"
                          value={
                            shippingData?.itemCountryId === null
                              ? ""
                              : shippingData?.itemCountryId
                          }
                          onChange={handleCountryChange}
                        >
                          <option value="">Choose country</option>
                          {commonResponse.countries?.value.map(
                            (item, Index) =>
                              item.Active === "Y" && (
                                <option key={Index}
                                  value={item.Id}
                                  data-code={item.CountryCode}
                                >
                                  {item.CountryName}
                                </option>
                              )
                          )}
                        </select>
                        {errors.itemCountryId && touched.itemCountryId ? (
                          <div className="required">{errors.itemCountryId}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span> State
                        </label>
                        <select
                          className="form-control input-seller"
                          name="itemStateId"
                          value={
                            shippingData.itemStateId === null
                              ? ""
                              : shippingData.itemStateId
                          }
                          onChange={onChange}
                        >
                          <option value="">Choose state</option>
                          {commonResponse.state?.value.map(
                            (item, Index) =>
                              item.Active === "Y" && (
                                <option key={Index} value={item.Id}>
                                  {item.StateName}
                                </option>
                              )
                          )}
                        </select>
                        {errors.itemStateId && touched.itemStateId ? (
                          <div className="required">{errors.itemStateId}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                   
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          {" "}
                          <span className="red-star">*</span>City
                        </label>
                        <input
                          type="text"
                          className="form-control input-seller"
                          name="itemCity"
                          value={shippingData.itemCity}
                          onChange={onChange}
                          placeholder="City"
                        />
                        {errors.itemCity && touched.itemCity ? (
                          <div className="required">{errors.itemCity}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          {" "}
                          <span className="red-star">*</span>Zip
                        </label>
                        <input
                          type="text"
                          // maxLength={5}
                          className="form-control input-seller"
                          name="itemZipCode"
                          value={
                            shippingData.itemZipCode === null
                              ? ""
                              : shippingData.itemZipCode
                          }
                          placeholder="Zip"
                          onChange={onChange}
                        />
                        {errors.itemZipCode && touched.itemZipCode ? (
                          <div className="required">{errors.itemZipCode}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          {" "}
                          <span className="red-star">*</span>Phone no
                        </label>
                        <input
                          type="text"
                          className="form-control input-seller"
                          name="sellerMobileNo"
                          value={
                            shippingData.sellerMobileNo === null
                              ? ""
                              : shippingData.sellerMobileNo
                          }
                          placeholder="Mobile no"
                          onChange={onChange}
                        />
                        {errors.sellerMobileNo && touched.sellerMobileNo ? (
                          <div className="required">
                            {errors.sellerMobileNo}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="seller-bottom-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                    <div className="fees-block">
                        <div className="fee-text">
                          Fees:<i className="fee-icon"></i>{" "}
                          <span className="fee-price">{sellerProductResponse?.platformFees?.data?.platFormFee}%</span>
                        </div>
                      </div>
                      <div className="seller-bottom-buttons">
                        <div className="seller-btm-text">
                          By selecting List item, you agree to pay the above
                          fees; accept the eFindit{" "}
                          <a href="#">User agreement</a>,{" "}
                          <a href="#">Payments terms</a> of Use and Marketing
                          terms of Service; acknowledge reading the User Privacy
                          Notice; and assume full responsibility for the item
                          offered and the content of your listing.
                        </div>
                      </div>

                      <div className="seller-button">
                        <button className="list-button" type="submit">
                          Save & next
                        </button>
                        {/* <button className="list-button bg-none">Preview</button> */}
                        <button
                          className="list-button bg-none"
                          onClick={() => {
                            submitAction = "primary";
                            handleSubmit();
                          }}
                        >
                          Save as draft
                        </button>
                        <button
                          className="list-button bg-none"
                          onClick={goToPrevious}
                        >
                          Back
                        </button>
                        {/* <button className="list-button bg-none">Cancel</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ShippingDetails;
